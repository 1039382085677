.main-auth-container {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: rgb(244, 249, 252);
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.right-side-auth-main {
  padding: 20px;
  width: 100%;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
}

.login-header {
  display: flex;
  padding: 40px;
  padding-bottom: 0px;
  flex-direction: column;
}

.login-header > h1 {
  font-size: 2rem;
}

.login-header > p {
  font-weight: 300;
  line-height: 25px;
  font-size: .9rem;
}

.return-back-link {
  margin-top: 20px;
  font-size: .9rem;
}

.locked-portal {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  padding: 40px;
  border-radius: 6px;
  text-align: center;
  gap: 10px;
  background-color: rgba(0,0,0,.8);
  height: 100%;
}

.locked-portal > h2 {
  font-size: 2rem;
}

.locked-portal > p {
  font-size: .8rem;
  max-width: 85%;
  line-height: 25px;
}

.locked-portal > i {
  font-size: 2.5rem;
}

.locked-portal > .timing-clock {
  font-size: 2rem;
}

.back-button-left-side-auth {
  position: absolute;
  top: 30px;
  border: none;
  background-color: transparent;
  font-weight: 500px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 30px;
  border-radius: 4px;
  font-size: .9rem;
  transition: .3s;
  cursor: pointer;
  padding: 8px 25px;
  padding-left: 15px;
  z-index: 1000;
}

.back-button-left-side-auth:hover {
  
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.back-button-left-side-auth > i {
  font-size: .7rem;
  margin-top: 2px;
}
