.search-filed-pricing-sheet {
  display: flex;
  gap: 20px;
  padding: 25px 0px;
  align-items: flex-end;
  padding-bottom: 5px;
  margin: 10px 20px;
  border-top: 1px solid #ddd;
}

.search-filed-pricing-sheet > button {
  margin-bottom: 11px;
  width: 25%;
}

.main-form-box {
  display: flex;
  flex-direction: column;
}

.header-single-line-filter {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  padding-bottom: 10px;
}

.line-h {
  display: flex;
  width: 15%;
  border-bottom: 1px solid #ddd;
}

.line-h-long {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.header-single-line-filter > p {
  white-space: nowrap;
  font-style: italic;
  font-weight: 400;
  font-size: .8rem;
  letter-spacing: 1.2px;
}

.extra-filter {
  overflow: hidden;
  height: 0;
}

.extra-filter-hide {
  animation: hideHight .3s forwards;
}

/* .search-filed-pricing-sheet > div {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 30%;
}

.search-filed-pricing-sheet > div:nth-child(1) {
  border-right: 1px solid #ddd;
  padding-right: 20px;
}

.search-filed-pricing-sheet > div:nth-child(2) {
  border-right: 1px solid #ddd;
  padding-right: 20px;
}

.search-filed-pricing-sheet > div > label {
  font-size: .9rem;
  font-weight: 500;
  color: #000;
} */

.search-field {
  width: 45% !important;
}


.header-section-dash-single-row {
  padding-right: 25px;
}

.origin-box-filter-single-line {
  display: flex;
  gap: 20px;
  padding: 0 20px;
}


.show-filter {
  animation: showHeight .3s forwards;
}

@keyframes showHeight {
  0% {
    height: 0px;
  }
  100% {
    height: 300px;
  }
}

@keyframes hideHight {
  0% {
    height: 300px;
  }
  100% {
    height: 0px;
  }
}

.advance-button-filter {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;
  padding-bottom: 20px;
}

.advance-button-filter > i {
  margin-top: 1px;
  font-size: 1.1rem;
  color: var(--primaryColor);
}

.flip-aero {
  transform: rotate(180deg);
}

.transition {
  transition: .3s;
}


.filter-button {
  width: fit-content;
  white-space: nowrap;
}

.no-rates-box {
  border: 2px dashed #ddd;
  border-radius: 5px;
  margin: 20px;
  margin-top: 0px;
  padding: 80px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 30px;
}

.tabs-rate-page {
  display: flex;
  align-items: flex-end;
}

.tabs-rate-page >  ul {
  display: flex;
  gap: 5px;
  padding: 0;
  margin-bottom: 0;
  width: 100%;
  list-style-type: none;
}

.tabs-rate-page >  ul > li {
  text-transform: capitalize;
  padding: 8px 20px;
  padding-left: 15px;
  padding-right: 25px;
  border-top-left-radius: 15px;
  color: #4e4e4e;
  transition: .3s;
  max-width: 200px;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  border-top-right-radius: 50px;
  background-color: #fff;
}

.selected-tab-rate {
  z-index: 10;
  color: #000  !important;
  box-shadow: 0 -0.2rem 1.425rem rgba(75, 70, 92, 0.1);
}

.sheets-container {
  border: 1px solid #ddd;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-x: scroll;
  outline: none;
}

.single-row-data {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: nowrap;
}

.single-field-excel {
  display: flex;
  min-width: fit-content;
}

.single-field-excel {
  flex-direction: column;
  display: flex;
  gap: 10px;
}

/* Container styling */
.custom-table-container {
  margin-top: 20px;
  overflow-x: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Table styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px; /* Ensure table is wide enough to avoid unnecessary wrapping */

}

/* Header styling */
.custom-table-header {
  background-color: #1a9d57;
  color: white;
  text-align: left;
  padding: 18px 16px;
  font-weight: 600;
  border-bottom: 2px solid #1a9d57;
  white-space: nowrap; /* Prevent wrapping */
}

/* Row styling */
.custom-table-row:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table-row:nth-child(odd) {
  background-color: #ffffff;
}

/* Selected row styling */
.custom-table-row.selected-row {
  background-color: #cce5ff;
}

/* Cell styling */
.custom-table-cell {
  padding: 25px 16px;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  color: #333333;
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis for overflow */
}

/* Checkbox styling */
.custom-table-cell input[type="checkbox"] {
  transform: scale(1.2);
  margin-right: 8px;
}

/* Hover effect */
.custom-table-row:hover {
  background-color: #e6f7ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-table-header, .custom-table-cell {
      font-size: 14px;
      padding: 10px;
  }
}