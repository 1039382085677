.logout-container {
  border: 1px solid;
  background-color: #fff;
  padding: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 500px;
  width: 100%;
  border-radius: 6px;
}

.top-info-logout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.top-info-logout > div {
  border: 1px solid #ddd;
  width: 80px;
  height: 80px;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 20px;
}

.logout-container > div > h2 {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 5px;
}

.logout-container > div > p {
  font-size: .9rem;
  font-weight: 300;
}

.button-bottom-logout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 70%;
}

