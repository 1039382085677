.welcome-box-container {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 400px;
  background-color: #fff;
  border-radius: 5px;
}

.name-captilization {
  text-transform: capitalize;
}

.header-name-welcome-box {
  font-size: 1rem;
  margin-bottom: 20px;
  border: 1px solid ;
  color: #fff;
  background-color: #000;
  padding: 8px 10px;
  font-size: .8rem;
  font-weight: 300;
  border-radius: 5px;
}

.text-info-welcome-user {
  margin-bottom: 30px;
  line-height: 25px;
  font-size: 1rem;
  padding: 0 20px;
}