/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.light-style .menu .app-brand.demo {
  height: 64px;
}

.dark-style .menu .app-brand.demo {
  height: 64px;
}

.app-brand-logo.demo {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  width: 34px;
  height: 24px;
}

.app-brand-logo.demo svg {
  width: 35px;
  height: 24px;
}

.app-brand-text.demo {
  font-size: 1.375rem;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}
.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu) .layout-page {
  padding-top: 78px !important;
}
/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/* Dropdown buttons going out of small screens */
@media (max-width: 576px) {
  #dropdown-variation-demo .btn-group .text-truncate {
    width: 254px;
    position: relative;
  }
  #dropdown-variation-demo .btn-group .text-truncate::after {
    position: absolute;
    top: 45%;
    right: 0.65rem;
  }
}

/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}


.menu-link {
  gap: 10px;
}

.app-brand {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 40px 0;
}


.side-bar-dash {
  border-top: 1px solid #ddd;
  padding: 20px 0 0 0 !important;
}

.right-side-header-dash {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.dash-header-user-info {
  display: flex;
  flex-direction: column;
}

.dash-header-user-info > * {
  margin: 0;
  padding: 0;
}

.dash-header-user-info > h3 {
  font-size: .9rem !important;
}

.dash-header-user-info > p {
  font-size: .75rem;
}

.sidebar-dashboard {
  height: 100%;
  position: fixed !important;
}

.page-dashboard-outlet-box {
  margin-left: 270px;
}


.child-div-full-width > div {
  width: 100% !important;
}

.no-result-found {
  flex-direction: column !important;
  gap: 20px !important;
  max-width: 400px;
  align-items: center;
  justify-content: flex-start;
}

.no-result-found > div {
  display: flex;
  align-items: flex-start;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.no-result-found > div > i {
  border-radius: 6px;
  font-size: 1.8rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #878787;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: #ffffff;
}

.no-result-found > div > h2 {
  margin-bottom: 10px;
  margin-top: 15px;
  font-weight: 600 !important;
  color: #000;
  font-size: 1.2rem;
}


.no-result-found > div > a {
  margin-top: 15px;
  color: var(--primaryColor);
  gap: 10px;
  display: flex;
}

.page-header-right-side {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.invites-info {
  margin-right: 15px;
  text-align: center;
}

.invite-progress-container {
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  width: 100%;
  margin: 5px 0;
}

.invite-progress-bar {
  background-color: #4caf50;
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.invite-status {
  color: #555;
  font-size: 0.85em;
}

.full-width {
  font-weight: bold;
  margin-bottom: 5px;
}