.delete-box-rate-carrier {
  border: 1px solid;
  background-color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 800px;
  width: 100%;
  border-radius: 10px;
}

.delete-box-rate-carrier > h2 {
  margin-bottom: 0 !important;
}

.delete-box-rate-carrier > p {
  margin-top: 0 !important;
  max-width: 600px;
  text-align: center;
  line-height: 25px;
  font-size: .9rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.delete-box-rate-carrier > .danger-icon{
  border: 1px solid;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.delete-box-rate-carrier > .action-buttons {
  display: flex;
  gap: 20px;
}