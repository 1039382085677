.billing-history-table {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 20px;
}

.billing-history-table > h3 {
  font-size: 1.2rem;
  
}

.billing-history-table > ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.billing-history-table > ul > li {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  border-top: 1px solid #ddd;
  padding: 15px 5px;

}


.billing-history-table > ul > li > div:last-child {
  text-align: right;
}

.billing-history-table > ul > li > div:last-child > span {
  background-color: var(--bs-success);
  color: #fff;
  padding: 3px 6px;
  font-size: .8rem;
  font-weight: 300;
  border-radius: 5px;
}

.change-sub-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-end;
}

.change-sub-box > p {
  font-size: .9rem;
  font-weight: 200;
}


.change-sub-box > button {
  font-size: .8rem;
  padding: 6px 15px;
}

.general-box-setting {
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.general-box-setting > h3 {
  font-size: 1.4rem;
  margin-bottom: 3px;
}

.general-box-setting > p {
  font-weight: 200;
  margin-bottom: 20px;
}

.single-col-box {
  display: grid;
  margin-bottom: 20px;
  align-items: center;
  grid-template-columns: 2fr 2fr;
}

.header-single-setting-box {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.header-single-setting-box > h4 {
  margin-bottom: 0;
  font-size: .9rem;
  font-weight: 400;
}

.header-single-setting-box > p {
  font-weight: 200;
  font-size: .8rem;
}

.label-small-box {
  width: fit-content;
  padding: 4px 10px;
}

.copy-text-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.copy-text-container > p {
  font-size: .9rem;
  color: var(--primaryColor);
  font-weight: 300;
}

.copy-text-container > i {
  cursor: pointer;
  font-size: 1.1rem;
}

.multi-col-box{
  display: grid;
  margin-bottom: 20px;
  align-items: start;
  grid-template-columns: 2fr 2fr;
}
.cridet-card-values{
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.list-of-user-carriers{
  display: flex;
  justify-content: space-between;
  gap: 12px;  
}
