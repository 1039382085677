

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position:relative;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
        fill: #4bb71b;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
        fill: #fff;
    }
}

@keyframes fill {
    100% {
      
      box-shadow: inset 0px 0px 0px 30px #fff;
    }
}

.box-content-center-membersip-success {
  border: 1px solid;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.failed-payment-box-container {
  border-radius: 5px;
  max-width: 600px;
  background-color: #fff;
  text-align: center;
  padding: 60px;
}

.failed-payment-box-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.failed-payment-box-container > div > h1 {
  font-size: 1.6rem;
  color: #000;
  color: var(--bs-warning);
  margin-bottom: 10px;
}

.failed-payment-box-container > div > p {
  font-weight: 300;
  line-height: 25px;
}

.failed-payment-box-container > img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 30px;
}

.dark-color-text {
  color: #000 !important;
}