.accept-box-container-rate {
  border-radius: 8px;
  max-width: 600px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: #fff;
}

.accept-box-container-rate > div {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}

.accept-box-container-rate > h1 {
  font-size: 2rem;
  margin-bottom: 0px;
}

.accept-box-container-rate > p {
  letter-spacing: .5px;
  line-height: 30px;
  font-weight: 300;
}

.reason-container {
  display: flex;
  text-align: left;
  margin-top: 10px !important;
  width: 100% !important;
}

.reason-container textarea {
  height: 130px;  
}


.input-field-container-generator {
  border: 1px solid var(--primaryColor);
  background-color: aliceblue;
  display: flex;
  width: 80%;
  margin-top: 20px;
  font-weight: 400;
  padding: 10px 15px;
  flex-direction: column;
  text-align: left;
  border-radius: 10px;
}

.input-field-container-generator > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
}

.input-field-container-generator > ul > li {
  display: flex;
  font-size: .8rem;
  font-weight: 350;
  justify-content: space-between;
  border-bottom: 1px solid var(--primaryColor);
  padding-bottom: 10px;
  padding-top: 10px;
}

.input-field-container-generator > ul > li:last-child {
  border: none;
}

.input-field-container-generator > ul > li > span {
  font-weight: 600;
}