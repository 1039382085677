.register-form-container {
  background-color: #fff;
  border-radius: 16px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 550px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.bottom-login-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgb(248, 250, 251);
  padding: 15px 20px;
  font-size: .9rem;
}


.login-form {
  width: 100%;
  display: flex;
  padding: 40px;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.remember-pass-forgot-pass-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  align-items: center;
}

.forgot-password-link {
  font-size: .9rem;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-label {
  color: var(--lightGrayColor);
  font-size: .95rem;
}

.agreement-section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  margin-top: 10px;
}

.password-bar {
  width: 100%;
  margin-top: -3px;
  margin-bottom: 5px;
}

.register-main-page {
  max-width: 1200px;
  gap: 20px;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.register-steps-info-page {
  max-width: 500px;
  padding-top: 50px;
}

.register-steps-info-page > img {
  margin-left: 25px;
}

.register-steps-info-page > ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  margin-top: 40px;
  gap: 50px;
  width: 100%;
}

.register-steps-info-page > ul > li {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 5;
}

.register-steps-info-page > ul > li > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.register-steps-info-page > ul > li > div > h2 {
  font-size: 1.1rem;
  margin: 0;
}

.register-steps-info-page > ul > li > div > p {
  font-size: .9rem;
  font-weight: 300;
}

.register-steps-info-page > ul > li > aside {
  background-color: #fff;
  width: 60px;
  border-radius: 50%;
  color: var(--primaryColor);
  height: 50px;
  font-size: 1.3rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-start-left {
  position: absolute;
  left: 25px;
  z-index: -1;
  top: 50%;
  border-left: 1px solid #bfbfbf;
  height: 150%;
}