.activity-table {
  width: 100%;
}

.activity-table > thead {
  display: flex;
  justify-content: space-between;
}

.activity-table > tbody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.activity-table > tbody > tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(212, 212, 212);
}

.activity-table > thead > th {
  font-weight: 400;
  padding: 20px;
  width: 20%;
  color: rgb(66, 66, 66);
}

.activity-table > thead > th:last-child{
  text-align: right;
}

.activity-table > tbody > tr,  .activity-table > thead{
  display: grid;
  grid-template-columns: 2fr 1.2fr 1fr 1.5fr;
  width: 100%;
}


.activity-table > tbody > tr > td, .activity-table > thead > th {
  width: 100%;
}


.activity-table > tbody > tr > td  {
  font-size: .95rem;
  flex-direction: column;
  font-weight: 300;
  padding: 20px;
  color: rgb(141, 141, 141);
  display: flex;
  gap: 10px;
}

.set-min-height {
  min-height: 500px;
  
}

.carrier-info-table-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 500px;
  width: 100%;
}

.carrier-info-table-box > h4 {
  font-size: .85rem;
  margin-bottom: 0;
  font-weight: 400;
}

.carrier-info-table-box > p {
  font-size: .7rem;
  font-weight: 300;
}

.last-item-table {
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
  display: flex;
}

.light-box {
  border: 1px solid #ddd;
  padding: 5px 18px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-size: .85rem;
  font-weight: 400;
  color: var(--success);
  border-radius: 6px;
}

.success-box {
  border: none;
  background-color: rgb(216, 235, 216);
  color: green;
}

.first-item-left-side {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0px;
  align-items: center;
}

.first-item-left-side > div:first-child > input{
  width: 43px !important;
}

.no-data-found-box {
  display: flex;
  height: 100% !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 90px;
}

.no-data-found-box > img {
  width: 180px;
}

.no-data-found-box > h2 {
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.light-color {
  color: #fff !important;
}