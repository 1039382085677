.welcome-box-setup-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  text-align: center;
}

.welcome-box-setup-account h2{
  font-size: 3rem;
  margin-bottom: 20px;
}

.welcome-box-setup-account p {
  margin-bottom: 40px;
  padding: 0 40px;
  line-height: 30px;
  font-weight: 300;
}

.welcome-box-setup-account button {
  width: 200px;
}

.form-business-info {
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

.full-width {
  width: 100%;
}

.header-business-box-step {
  flex-direction: column;
  display: flex;
}

.header-business-box-step > h2 {
  margin-bottom: 5px;
  font-size: 1.5rem;
}

.header-business-box-step > p {
  font-weight: 300;
  font-size: .9rem;
}

.form-business-info * {
  width: 100% !important;
}

.domain-form .info-not-department-adding{
  width: 100%;
  max-width: 550px;
}

.max-width-500 {
  max-width: 560px;
  width: 100%;
}

.select-field-account-complection {
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.form-personal-account {
  flex-direction: column;
  margin-top: 20px;
}


.form-personal-account > div {
  width: 100% !important;
}

.plan-box-single {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  margin-top: 20px;
  min-width: 650px;
  width: 100% !important;
}

.plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.left-side-plan-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.left-side-plan-header > h1 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0;
  color: #000;
}

.left-side-plan-header > p {
  font-size: .9rem;
  font-size: 200;
}

.left-side-plan-header-cost {
  display: flex;
  gap: 3px;
  align-items: baseline;
  justify-content: center;
}

.left-side-plan-header-cost > h2 {
  font-size: 2rem;
  margin-bottom: 0;
  color: #000;
}

.user-selection-input-plan-details {
  border: 1px solid #ddd;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
  padding: 15px 15px;
  border-radius: 8px;
}

.user-selection-input-plan-details > .single-item-user-member {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
}

.user-selection-input-plan-details > .single-item-user-member > p {
  text-transform: uppercase;
  font-size: .7rem;
}

.user-selection-input-plan-details > .single-item-user-member > span {
  background-color: #000;
  width: 22px;
  align-items: center;
  display: flex;
  font-weight: 700;
  border-radius: 5px;
  justify-content: center;
  height: 22px;
  font-size: .8rem;
  color: #fff;
}

.for-sale-tag {
  font-size: .7rem;
  border: 1px solid;
  padding: 2px 8px;
  border-radius: 50px;
  background-color: green;
  color: #fff;
}

.body-membership-info {
  padding: 0 20px;
  margin-bottom: 15px;
}

.body-membership-info > div {
  display: flex;
  gap: 1px;
  flex-direction: column;
}

.body-membership-info > div > h1 {
  font-size: 1rem;
  margin-bottom: 0;
  color: #000;
}

.body-membership-info > div > p {
  font-size: .9rem;
  font-weight: 300;
}

.list-of-features {
  padding: 0;
  list-style-type: none;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.list-of-features > li {
  font-weight: 400;
  display: flex;
  gap: 8px;
  color: #000;
  align-items: center;
}

.list-of-features > li > i {
  color: green;
  font-size: 1.2rem;
}

.user-action-slider {
  width: 100% !important;
  gap: 15px;
  min-width: 650px;
  display: flex;
}

.user-action-slider > div {
  width: 100%;
}

.place-order-button-membership {
  border-top: 1px solid #ddd;
  display: flex;
  padding: 20px 15px;
}