.header-setting-section {
  padding: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
}

.header-setting-section > h2 {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.content-inside-setting {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.side-bar-setting {
  display: flex;
  max-width: 230px;
  width: 100%;
}

.side-bar-setting > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-top: 15px;
  gap: 8px;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.side-bar-setting > ul > li {
  padding: 6px 10px;
  transition: .3s;
  cursor: pointer;
  border-radius: 5px;
}

.side-bar-setting > ul > li:hover {
  background-color: #e9e9e9;
}

.outlet-setting-box {
  border-left: 1px solid #ddd;
  width: 100%;
}