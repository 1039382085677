.container-creating-rates {
  border: 1px solid;
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  overflow: hidden;
}

.title-create-module {
  margin: 0 !important;
  font-size: 1rem;

}

.header-creating-rates {
  top: 0;
  width: 100%;
  padding-top: 30px !important;
  padding: 20px 0;
  justify-content: space-between;
  display: flex;
}

.header-creating-rates > button, .header-creating-rates > div > button {
  padding: 0;
  gap: 10px;
  font-size: .85rem !important;

}

.header-creating-rates {
  padding-top: 18px !important;
  padding: 18px 20px;
}

.header-creating-rates > button {
  padding: 0 !important;
}

.content-box-uploading-rates {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
}

.progress-bar-steps-uploading-data {
  background-color: #ddd;
  width: 100%;
}

.progress-data-value {
  padding: 1px 0;
  transition: .3s;
  background-color: var(--bs-success);
  border: 1px solid var(--bs-success);
}

.back-and-title-section {
  margin: 0 !important;
  gap: 20px;
  padding: 0 !important;
  align-items: center;
}