.primary-button {
  width: 100%;
  background-color: var(--primaryColor);
  border: none;
  color: var(--textLightColor);
  font-size: 1rem;
  padding: 10px 20px;
  gap: 10px;
  transition: .3s ;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.primary-button:focus {
  box-shadow: var(--primaryColorLight) 0px 1px 4px, var(--primaryColorLight) 0px 0px 0px 3px;
}

.primary-button:disabled {
  cursor: wait;
  opacity: .7;
}

.delete-button {
  border-radius: 5px;
  transition: .3s ;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: .8rem;
  padding: 10px 20px;
  background-color: var(--bs-danger);
}

.delete-button:disabled {
  cursor: wait;
  opacity: .7;
}



.checkbox-wrapper-13 input[type=checkbox] {
  --active: var(--primaryColor);
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, .3);
  --border: #BBC1E1;
  --border-hover: #275EFE;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}


.checkbox-wrapper-13 input[type=checkbox]:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
}

.checkbox-wrapper-13 input[type=checkbox]:checked {
  --b: var(--active);
  --bc: var(--active);
  --d-o: .3s;
  --d-t: .6s;
  --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
}

.checkbox-wrapper-13 input[type=checkbox]:disabled {
  --b: var(--disabled);
  cursor: not-allowed;
  opacity: 0.9;
}

.checkbox-wrapper-13 input[type=checkbox]:disabled:checked {
  --b: var(--disabled-inner);
  --bc: var(--border);
}

.checkbox-wrapper-13 input[type=checkbox]:disabled + label {
  cursor: not-allowed;
}

.checkbox-wrapper-13 input[type=checkbox]:hover:not(:checked):not(:disabled) {
  --bc: var(--border-hover);
}

.checkbox-wrapper-13 input[type=checkbox]:focus {
  box-shadow: 0 0 0 var(--focus);
}
.checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
  width: 22px;
}
.checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
  opacity: var(--o, 0);
}
.checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
  --o: 1;
}
.checkbox-wrapper-13 input[type=checkbox] + label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 4px;
}

.checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
  border-radius: 7px;
}
.checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
  width: 5px;
  height: 9px;
  border: 2px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 7px;
  top: 4px;
  transform: rotate(var(--r, 20deg));
}
.checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
  --r: 43deg;
}

.checkbox-wrapper-13 * {
  box-sizing: inherit;
  align-items: center;
  justify-content: center;
  display: flex;
}

.checkbox-wrapper-13 *:before,
.checkbox-wrapper-13 *:after {
  box-sizing: inherit;
}

.checkbox-wrapper-13 > label {
  font-weight: 300;
  font-size: .78rem;
  width: 90%;
  line-height: 22px;
}

.checkbox-wrapper-13 {
  padding-top: 2px;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
}