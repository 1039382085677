.invitation-action-button-carrier-portal {
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  display: flex;
  width: 100% !important;
  max-width: 100%;
}

.carrier-invatation > thead {
  grid-template-columns: 2fr 1fr 1.5fr !important;
}

.carrier-invatation > tbody > tr{
  grid-template-columns: 2fr 1fr 1.5fr !important;
}


.invitation-action-button-carrier-portal > button { 
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: .9rem;
}

.child-div-full-width > div {
  width: 100% !important;
}

.rate-carrier-portal-table > thead {
  grid-template-columns: 2fr 2fr 1fr 2fr 1.5fr !important;
}

.rate-carrier-portal-table > tbody > tr {
  grid-template-columns: 2fr 2fr 1fr 2fr 1.5fr !important;
}

.navigation-aero-rate-table {
  align-items: center;
  display: flex;
  gap: .4rem !important;
  font-weight: 400;
}


.navigation-aero-rate-table > i {
  color: var(--primaryColor);
  
  font-size: .8rem;
}

.show-filter-rate {
  animation: showFilterRate .3s forwards;
}

@keyframes showFilterRate {
  0% {
    height: 0;    
  }
  100% {
    height: 440px;
  }
}

.primary-color {
  background-color: var(--primaryColor);
  color: #fff;
  font-size: 1rem;
}

.primary-color:hover {
  color: #fff;
  background-color: var(--primaryColorLightHover);
}

.end-table-col {
  justify-content: flex-end;
}

.last-table-col {
  width: 100%;
  padding-right: 0 !important;
}

.card-box {
  padding: 20px 25px;
}

.load-more-rates-carrier-portal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}