

.loader-spin {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #fff;
  border-bottom-color: transparent !important;
  border-radius: 50% !important;
  display: inline-block !important;
  padding: 0;
  margin: 2px 0;
  animation: rotation .7s linear infinite;
}

.fullSpinner {
  width: 45px !important;
  height: 45px !important;
  border: 2px solid var(--primaryColor) !important;
  border-bottom-color: transparent !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.loading-full-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.loading-full-screen > h1 {
  font-weight: 400;
  font-size: 1rem;
}