.rate-new-rates-table {
  width: 100%;
}

.rate-accepted-rates-table > thead, .rate-accepted-rates-table > tbody > tr{
  grid-template-columns: .8fr 1fr .8fr 1.5fr 2fr !important;
}

.rate-new-rates-table > thead {
  grid-template-columns: .8fr 1fr .8fr 1.5fr 1fr !important;
}

.rate-new-rates-table > tbody > tr {
  white-space: nowrap;
  grid-template-columns: .8fr 1fr .8fr 1.5fr 1fr !important;
}

.over-flow-box {
  overflow-x: scroll;
}