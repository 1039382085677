.full-page-container-invitation {
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.box-container-small-invitation {
  min-width: 530px !important; 
  max-width: 530px !important;
  width: 100% !important;
  position: relative;
  border: 1px solid #ddd;
  padding: 40px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.box-container-small-invitation > h2 {
  font-size: 1.5rem;
}

.company-name-highlight {
  font-size: .9rem;
  border-radius: 5px;
  padding-left: 20px;
  color: #000;
  border: 1px solid #e6e1e1;
  background-color: #e6e1e1;
  display: flex;
  font-size: .85rem;
  font-weight: 300 !important;
  align-items: baseline;
  justify-content: flex-start;
  overflow: hidden;
  gap: 20px;
}

.company-name-highlight > div {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 8px;
  font-size: .9rem;
  width: 100%;
}

.invitation-message {
  margin-bottom: 20px;
  line-height: 25px;
  letter-spacing: .5px;
}

.invitation-header {
  margin-top: 15px;
  margin-bottom: 10px;
  line-height: 40px;
}

.icon-box-container-invitation {
  border: 1px solid #ddd;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;
  border-radius: 8px;
}

.action-button-invitation {
  display: flex;
  gap: 10px;
  padding-top: 10px;
}

.overlap-loading-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #00000098;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  gap: 20px;
}

.overlap-loading-box > .loader-spin {
  scale: 2;
}