.payment-box-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cost-right-side-dashboard {
  text-align: right;
}

.cost-right-side-dashboard > div {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
  gap: 7px;
}
.cost-right-side-dashboard > div > h4 {
  font-size: 2rem;
}

.cost-right-side-dashboard > div > small {
  margin-bottom: 6px;
  font-weight: 200;
}

.progress-class {
  width: 100%;
}

.progress-bar-current-plan {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.progress-bar-current-plan > div {
  padding-left: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.progress-bar-current-plan > div > .section-header-progress-dashboard {
  margin-left: -5px;
  font-size: .7rem;
  display: flex;
  justify-content: space-between;
}

.progress-bar-current-plan > div > .section-header-progress-dashboard > small {
  font-size: .7rem;
}

.eye-toggle-button {
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: green;
  margin-right: 5px;
  background-color: transparent;
}