.user-content-setup-finish-auth{
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.header-info-account-setup {
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 20px 20px;
  align-items: center;
  justify-content: center;

}

.setups-left-side {
  border-right: 1px solid #ddd;
  height: 100%;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.setups-list-account {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  padding: 40px;
  padding-top: 20px;
}

.setups-list-account > li {
  display: flex;
  gap: 20px;
  position: relative;
  align-items: flex-start;
}

.number-id {
  display: flex;
  border: 2px solid #ddd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  color: #a1a2a3;
  font-weight: 500;
  background-color: #f6f6f6;
  transition: .3s;
  justify-content: center;
  padding: 0 !important;
}

.setups-list-account > li > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 8px;
}

.setups-list-account > li > div > h3 {
  font-size: 1rem;
  margin: 0;
  color: #a1a2a3;
}

.setups-list-account > li > div > p {
  font-weight: 200;
  font-size: .8rem;
}

.line-setup-connect {
  border: 1px solid #ddd;
  position: absolute;
  left: 20px;
  top: 39px;
  height: 100%;
  transition: .3s;
}

.help-section-account-setup {
  padding: 40px;
}

.help-section-account-setup > h3 {
  font-size: 1rem;
  color: #000;
  margin-bottom: 10px;
  margin-top: 10px;
}

.help-section-account-setup > i {
  font-size: 2rem;
}

.setups-right-side {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.active-selection-step {
  color: #000 !important;
}

.active-selection-step > div > h3 {
  color: #2f2f2f !important;
}


.active-selection-step > .number-id {
  border-color: var(--primaryColor);
  color:  var(--primaryColor);
}

.active-selection-step > .line-setup-connect {
  border-color: var(--primaryColor);
}

.submitting-loading-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 100px 20px;
  width: 450px;
  border-radius: 5px;
  max-height: 400px;
}