:root {
  --primaryColor: #0842de;
  --primaryColorLight: #a3c5f6;
  --primaryColorLightHover: #3283f5;
  --textLightColor: #FFF;
  --lightGrayColor: #a1a2a3;
  --sail-color-text-red: #cd4368;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

button {
  cursor: pointer;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.text-link {
  font-weight: 400;
  text-decoration: none;
  color: var(--primaryColor);
}

.text-button {
  border: none;
  background-color: transparent;
}

.app-brand.demo {
  height: 64px;
}

.app-brand.demo {
  height: 64px;
}

.app-brand-logo.demo {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  width: 34px;
  height: 24px;
}

.app-brand-logo.demo svg {
  width: 35px;
  height: 24px;
}

.app-brand-text.demo {
  font-size: 1.375rem;
}


.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu) .layout-page {
  padding-top: 78px !important;
}


.content-wrapper .navbar {
  z-index: auto;
}


.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

@media (max-width: 576px) {
  #dropdown-variation-demo .btn-group .text-truncate {
    width: 254px;
    position: relative;
  }
  #dropdown-variation-demo .btn-group .text-truncate::after {
    position: absolute;
    top: 45%;
    right: 0.65rem;
  }
}


.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}

.layout-demo-placeholder img {
  width: 900px;
}

.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}

.menu-link.active{
  background: linear-gradient(72.47deg, #7367f0 22.16%, rgba(115, 103, 240, 0.7) 76.47%);
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.48);
  color: #fff !important;
}

.menu-link.active:hover{
  background: linear-gradient(72.47deg, #7367f0 22.16%, rgba(115, 103, 240, 0.7) 76.47%)!important;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.48)!important;
  color: #fff !important;
}

.bg-menu-theme .menu-inner .menu-sub .menu-item:not(.active) > .menu-link::before{
  content: unset;
}

.bg-menu-theme .menu-inner .menu-sub .menu-item:not(.active) > .menu-link{
  padding-left: 30px;
}

.view-wrapper strong {
  font-size: 13px;
  font-weight: 600;
  padding: 3px 10px;
  background-color: #f9f9f9;
  float: left;
  width: fit-content;
  display: block;
  border-radius: 30px;
}

.content-sizing {
  max-width: 1144px;
  width: 100%;
}

.line-container-style {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  z-index: 1 !important;
}

.line-container-style > .lines {
  border-right: 1px dashed #e7e5e5;
}

.primary-button-link {
  background-color: var(--primaryColor);
  padding: 8px 20px;
  border-radius: 6px;
  transition: .3s;
  color: #fff;
  gap: 5px;
  display: flex;
}

.primary-button-link:hover {
  background-color: var(--primaryColorLightHover);
  color: #fff !important;
}

.button-link {
  border: none;
  transition: .3s;
  background-color: transparent;
  color: var(--primaryColor);
}

.button-link:hover {
  color: #000;
}

.success_message {
  border-radius: 10px;
  width: 85%;
  padding: 10px 15px;
  color: green;
  font-size: .9rem;
  font-weight: 400;
  background-color: rgb(196, 246, 196);
  margin-top: 10px;
  margin-bottom: -10px;
}

.warning_message {
  border-radius: 10px;
  width: 85%;
  padding: 10px 15px;
  color: rgb(0, 0, 0);
  font-size: .9rem;
  font-weight: 400;
  background-color: rgb(246, 237, 196);
  margin-top: 10px;
  margin-bottom: -10px;
}

.primary-tag {
  color: var(--primaryColor);
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 20px;
}