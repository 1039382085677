.rate-type-container {
  max-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0;
  display: flex;
  justify-content: center;
}

.rate-type-container > button {
  width: 35%;
}

.select-field-step-1-rate {
  gap: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.upload-sign-create-rate{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  text-align: center;
  gap: 20px;
}

.upload-sign-create-rate > h3 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.upload-sign-create-rate > i {
  font-size: 3rem;
}

.dropzone {
  border: 1.2px dashed #ddd;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 60px 20px;
  margin-top: 20px;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s;
}

.dropzone > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropzone > div > i {
  font-size: 3rem;
}

.dropzone > div > p {
  font-weight: 500;
  color: #000;
}

.dropzone.active {
  border-color: var(--primaryColor);
}

.select-tsmode-step-1 {
  display: flex;
  list-style-type: none;
  padding: 0;
  gap: 20px;
  justify-content: space-between;
}

.select-tsmode-step-1 > li {
  border: 1px solid #ddd;
  flex-direction: column;
  display: flex;
  cursor: pointer;
  position: relative;
  transition: .3s;
  width: 33.33%;
  border-radius: 10px;
  padding: 30px 20px;
  align-items: center;
}

.select-tsmode-step-1 > li > h2 {
  font-size: 1.5rem;
  margin-bottom: 10px
}

.select-tsmode-step-1 > li > p {
  font-size: .9rem;
  font-size: 300;
  text-align: center;
}

.icon-tmode {
  border: 1px solid #ddd;
  border-radius: 560px;
  padding: 20px;
  width: 80px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  height: 80px;
}

.upload-box-container {
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.upload-box-container > h2 {
  font-size: 1.8rem;
}

.upload-box-container > button {
  width: 50%;
}

.file-info-upload-file {
  display: flex;
  width: 100%;
  font-size: .8rem;
  font-weight: 300;
  margin-top: 5px;
  padding: 0 5px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.manual-data-entry-box-up-data {
  width: 100%;
  background-color: #eee;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
  margin-bottom: 30px;
}

.manual-data-entry-box-up-data > button {
  padding: 5px 20px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #cacaca;
}

.manual-data-entry-box-up-data > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 60%;
}

.manual-data-entry-box-up-data > div > h3 {
  font-size: .95rem;
  margin-bottom: 0;
}

.manual-data-entry-box-up-data > div > p {
  font-size: .8rem;
  max-width: 3;
  font-weight: 300;
}

.data-form-uploading-form {
  width: 100%;
  height: 100%;
  padding: 30px 40px;
}

.header-review-data-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.header-review-data-form > div {
  width: 100%;
}


.header-review-data-form > button {
  width: 20%;
}

.header-review-data-form > div > h2 {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.single-rate-item {
  border: 1px solid #ddd;
  position: relative;
  padding: 20px 30px;
  flex-wrap: wrap;
  width: 100% !important;
  margin-bottom: 30px;
  border-radius: 10px;
  display: flex;
  align-self: center;
}

.field-box {
  flex-wrap: wrap;
  display: flex;
}

.field-box > * {
  width: 23%;
}

.main-top-field {
  justify-content: space-between;
}

.overborder-label {
  position: absolute;
  top: -12px;
  background-color: #fff;
  padding: 0px 20px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.button-add-row-container {
  display: flex;
  padding: 10px 0;
}

.button-add-row-container > button {
  width: 100%;
  gap: 10px;
  display: flex;
}

.overborder-close-button {
  position: absolute;
  top: -15px;
  background-color: var(--bs-danger);
  color: #fff;
  border-radius: 50%;
  border: none;
  width: 25px;
  height: 40px;
  right: -20px;
}

.center-button-upload-file {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.center-button-upload-file > button {
  width: 30%;
}

.file-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-info > button {
  font-size: .8rem;
  padding: 8px 20px;
}

.file-info > p {
  color: #858585 !important;
  margin-bottom: 0 !important;
  font-weight: 300 !important;
  margin-top: -5px;
  font-size: .8rem;
}

.file-info > i {
  margin-bottom: 5px;
  color: var(--primaryColor);
}

.over-flow-loading-box {
  border: 1px solid;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #000000bc;
}

.over-flow-loading-box > p {
  color: #fff !important;
}

.active-selection-tsmode {
  border-color: var(--primaryColor) !important;
}

.selected-checkbkox-tsmode {
  position: absolute;
  font-size: 1.5rem;
  top: -15px;
  right: -10px;
  padding-left: 5px;
  color: var(--primaryColor);
  background-color: #fff;
}

.action-upload-rates-ts-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  padding: 20px 0px;
  padding-bottom: 50px;
  border-radius: 10px;
  gap: 50px;
}

.action-upload-rates-ts-mode > button {
  width: 300px;
}

.action-upload-rates-ts-mode > div {
  width: 100%;
}