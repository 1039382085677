.header {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  border-bottom: 1px solid #ededed;
}

.header-wrapper {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
}

.nav > ul {
  display: flex;
  justify-content: center;
  gap: 15px;
  list-style-type: none;
  padding: 0;
  align-items: center;
  margin: 0;
}

.nav > ul > li > a {
  color: #575757;
  font-weight: 400;
  padding: 6px 12px;
  border-radius: 5px;
  transition: .3s;
  font-weight: 500;
  font-size: .9rem;
  cursor: pointer;
}

.nav > ul > li > a:hover {
  color: var(--primaryColor);
  /* background-color: #000; */
}

.right-side-header {
  display: flex;
  align-items: center;
  gap: 25px;
}

.left-side-header {
  display: flex;
  gap: 60px;
}