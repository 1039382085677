.container-pop-up-box-error-account {
  border: 1px solid;
  background-color: #fff;
  max-width: 600px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  padding: 60px 60px;
  flex-direction: column;
}

.container-pop-up-box-error-account > img {
  width: 100%;
  max-width: 250px;
  object-fit: contain;
  aspect-ratio: 3/2;
}

.container-pop-up-box-error-account > i { 
  font-size: 4rem;
  color: var(--bs-danger);
}

.container-pop-up-box-error-account > div {
  margin-bottom: 40px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.container-pop-up-box-error-account > div > h2 {
  margin-bottom: 5px;
  font-size: 1.3rem;
  color: #000;
}


.container-pop-up-box-error-account > div > p {
  font-size: .8rem;
  line-height: 25px;
  font-weight: 300;
}

.container-pop-up-box-error-account > button {
  width: fit-content;
}