.logo-container > img {
  margin-left: 20px;
  margin-bottom: 30px;
}

.login-form-container {
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 520px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.bottom-login-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgb(248, 250, 251);
  padding: 15px 20px;
  font-size: .9rem;
}

.login-form {
  width: 100%;
  display: flex;
  padding: 40px;
  padding-top: 20px;
  padding-bottom: 30px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.remember-pass-forgot-pass-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  align-items: center;
}

.forgot-password-link {
  font-size: .9rem;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-label {
  color: var(--lightGrayColor);
  font-size: .95rem;
}

.fs-12 {
  font-size: .9rem;
  font-weight: 400;
}