
.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10;
  border: 1px solid;
}

.material-field {
  width: 100%;
  transition: .3s;
  display: flex;
  flex-direction: column;
}

.material-field-input {
  font-size: 14px;
  padding: 9.5px 15px;
  width: calc(100%);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  display: block;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.material-field-input:focus {
  outline: none;
  border-color: var(--primaryColor);
}

.material-field-input:focus ~ .material-field-label {
  color: var(--primaryColor);
  transform: scale(0.75) translate(-13px, -30px);
}

.material-field-label {
  color: #373434;
  font-size: .83rem;
  padding: 10px 0;

}

.error-text {
  color: var(--sail-color-text-red);
  margin-top: 10px;
  height: 0;
  font-size: .8rem;
  overflow: hidden;
}

.error-animate-in {
  animation: show .3s forwards;
} 

.error-animate-out {
  animation: hide .1s forwards;
} 

@keyframes show {
  0% {
    height: 0;
  }
  100% {
    height: 20px;
  }
}

@keyframes hide {
  0% {
    height: 20px;
  }
  100% {
    height: 0px;
  }
}

.select-option{
  font-size: .9rem !important;
}

.select-option > .css-13cymwt-control {
  border-radius: 5px;
  padding: 2px 4px;
}

.select-option > .css-13cymwt-control:focus {
  border-radius: 5px;
  padding: 2px 4px;
  outline: none;
}

.otp-container-style {
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.single-otp-field {
  width: 60px !important;
  height: 60px;  
  font-size: 1.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-field {
  margin-bottom: 25px;
}