.generation-key-section {
  padding-left: 24px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.generation-key-section > h2 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.generation-key-section > p {
  font-size: .8rem;
  font-weight: 300;
}

.generation-key-section > div {
  border: 2px dashed #ddd;
  margin-top: 20px;
  border-radius: 6px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-security-token {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
}

.box-security-token > div {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 40px;
}

.box-security-token > div > img {
  border: 1px solid #ddd;
  filter: grayscale(0.8);
  border-radius: 6px;
}

