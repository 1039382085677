.notification-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-menu > span {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: .6rem;
  font-weight: 500;
  background-color: brown;
  color: #fff;
}

.upload-button-main {
  width: 90% !important;
  margin-bottom: 15px !important;
  margin-left: 15px !important;
}