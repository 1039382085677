.department-field {
  gap: 20px;
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

.department-field > i {
  font-size: 2rem;
  color: #a8a8a8;
  margin-bottom: 10px;  
}

.info-not-department-adding {
  border: 1px solid #ddd;
  margin: 25px;
  padding: 20px;
  display: flex;
  gap: 25px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 6px;
}

.info-not-department-adding > img {
  width: 200px;
  filter: grayscale(.8);
}

.info-not-department-adding > p {
  font-size: .85rem;
  line-height: 25px;
}