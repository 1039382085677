.request-form-submit {
  max-width: 550px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
}

.header-request {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px;
  gap: 20px;
  justify-content: flex-start;
}

.header-request > i {
  border: 1px solid;
  font-size: 1.3rem;
  border-radius: 4px;
  background-color: var(--primaryColor);
  color: #fff;
  padding: 10px 16px;
}

.header-request > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header-request > div > h2 {
  font-size: 1.1rem;
}

.header-request > div > p {
  font-size: .9rem;
}

.header-request > div > * {
  margin: 0;
  padding: 0;
}

.request-form-submit > form {
  padding: 10px 25px 20px;
}

.action-list-button {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  justify-content: flex-end;
}

.action-list-button > div {
  display: flex;
  gap: 20px;
}