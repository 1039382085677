.main-page {
  position: relative;
  background-color: #fff !important;
}

.section-banner-post {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}

.line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(24, 24, 24, 0.05);
  overflow: hidden;
}

.line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(45, 45, 45, 0) 0%, #5e5d5d 75%, #a9a9a9 100%);
  -webkit-animation: drop 7s 0s infinite;
          animation: drop 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.line:nth-child(1) {
  margin-left: -20%;
}

.line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.line:nth-child(2) {
  margin-left: -40%;
}

.line:nth-child(2)::after {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.line:nth-child(4) {
  margin-left: 20%;
}

.line:nth-child(4)::after {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.line:nth-child(5) {
  margin-left: 40%;
}

.line:nth-child(5)::after {
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s;
}

@-webkit-keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

.section-container-banner {
  z-index: 100;
  display: flex;
  gap: 100px;
  justify-content: space-between;
  padding: 80px 0;
}


.left-side-info-banner {
  max-width: 550px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}



.main-page-front-text {
  font-size: 4rem;
  color: #3d3d3d;
  line-height: 75px;
  letter-spacing: -1px;
  text-transform: capitalize;
  font-weight: 500;
}


.main-page-sub-text {
  line-height: 30px;
  color: #5e5d5d;
  font-size: 1.2rem;
  font-weight: 400;
}

.tag-span {
  color: #fff;
  width: fit-content;
  border-radius: 20px;
  font-weight: 500;
  background-color: rgba(12, 12, 12, .5);
  padding: 4px 14px;
  font-size: .85rem;
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.banner-action-button {
  display: flex;
  gap: 25px;
  max-width: 800px;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-start;
}

.banner-action-button > button {
  width: 45%;
}

.banner-action-button > a {
  align-items: center;
  width: 200px;
  display: flex;
  gap: 8px;
}

.banner-action-button > a > i {
  margin-top: 3px;
  font-size: 1.2rem;
}

.right-hand-side-section-banner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.dashboard-image-banner {
  border: 1px solid #ddd; 
  position: absolute;
  width: 1100px;
  overflow: hidden;
  height: 95%;
  background-color: #ece8e864;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  right: -550px;
  top: 0px;
}

.dashboard-image-banner > img {
  margin-left: 0px;
}

.phone-box-banner {
  height: 95%;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 40px;
  margin-left: -60px;
  display: flex;
  align-items: center;
  justify-content: baseline;
  z-index: 100;
}

.phone-box-banner > ul {
  display: grid;
  width: 100%;
  gap: 100px;
  grid-template-columns: 1fr;
  list-style-type: none;
  padding: 0;
}

.phone-box-banner > ul > li {
  height: 200px;
  max-width: 200px;
  padding: 20px;
  box-shadow: rgba(8, 65, 222, 0.29) 0px 4px 12px;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
}

.phone-box-banner > ul > li > img {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.abosolute-box {
  position: absolute;
  right: 200px;
  z-index: 90 !important;
  top: 30%;
}

.overview-container {
  display: flex;
  flex-direction: column;
  background-color: #f7f8fc;
  align-items: center;
  
  justify-content: center;  
}

.over-view-content {
  padding: 100px 0;
}

.overview-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overview-header-title {
  margin-bottom: 30px;
  font-size: 3rem;
  line-height: 55px;
  margin-top: 20px;
  font-weight: 600;
 
  color: #000;
  text-align: center;
}

.overview-header-paragraph {
  font-size: 1.2rem;
  max-width: 700px;
  justify-content: center;
  text-align: center;
}

.overview-list-data {
  margin-top: 80px;
  display: flex;
  padding: 0;
  list-style-type: none;
  gap: 20px;
  justify-content: space-between;
}

.overview-list-data-single {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 0;
}

.overview-list-data-single > div {
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
}

.overview-list-data-single > div > h3 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1.4rem;
  color: #000;
}

.overview-list-data-single > div > p {
  font-size: 1.05rem;
}

.overview-list-data-single > div > div {
  width: fit-content;
  padding: 15px;
  border-radius: 8px;
  background-color: #000;
  margin-bottom: 20px;
}

.image-placer-holder {
  background-color: #f0eded;
  margin: 16px;
  height: 250px;
  overflow: hidden !important;
  padding: 5px !important;
  border-radius: 15px;
}

.image-placer-holder > img {
  width: 100%;
  height: 100%;
  object-fit: COVER;
  border-radius: 15px;
}

.main-scroll-effect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 120px 0;
}

.main-scroll-effect-content {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.left-side-block-fixed {
  width: 50%;
}

.test-image-block {
  width: 200px;
  height: 500px;
}

.left-side-block-fixed-header {
  font-weight: 500;
  color: #000;
  max-width: 400px;
  margin-top: 20px;
  font-size: 2.3rem;
}

.left-side-block-fixed-para {
  font-size: 1.1rem;
  line-height: 30px;
}

.main-sides-photo-holder {
  border: 1px solid #ddd;
  width: 400px;
  height: 450px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breaker-middle-get-started {
  padding: 80px 40px;
  border-radius: 20px;
  max-width: 1140px;
  background-color: #262626;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: baseline;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  flex-direction: column;
  width: 100%;
}

.breaker-middle-get-started > .lines > .line {
  background-color: #343434;
}

.breaker-middle-get-started > h2 {
  font-size: 4em;
  z-index: 1;
  color: #fff;
  max-width: 700px;
  line-height: 65px;
  text-align: center;
  font-weight: 700;
}

.breaker-middle-get-started > p {
  font-size: 1.1rem;
  max-width: 400px;
  text-align: center;
}

.single-block-list-style {
  list-style-type: none;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.single-block-list-style > li {
  color: #000;
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 5px;
  justify-content: flex-start;
}

.single-block-list-style > li > i {
  font-size: 1.5rem;
}

.faq-info-main-page {
  width: 100%;
  position: relative;
  background-color: transparent;
}

.faq-info-main-page {
  background-color: #171717;
}

.faq-box-main-content {
  padding: 80px 0;
}

.faq-info-main-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-content {
  display: flex;
  justify-content: space-between;
}

.left-title-faq > h2 {
  color: #fff;
  font-weight: 650;
  font-size: 2.6rem;
  line-height: 55px;
}

.faq-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 120px;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq-list-container-main-page {
  display: flex;
  flex-direction: column;
  margin: 50px 0 30px 0;
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 30px;
  gap: 20px;
  list-style-type: none;
  padding-left: 0;
  max-width: 550px;
}

.faq {
  width: 100%;
  border: 1px solid #747474;
  padding: 20px 30px;
  border-radius:30px;
  cursor: pointer;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.question > h3 {
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0;
}

.question > i {
  color: var(--e-global-color-text);
  font-size: 1.2rem;
  transition: .3s;
}

.answer {
 max-height: 0; 
 overflow: hidden;
 transition: max-height .6s ease;
}

.answer p {
  padding-top: 1rem;
  line-height: 1.6rem;
  font-size: .8rem;
  font-weight: 200;
  color: #fff;
}

.faq.active .answer{
  max-height: 300px;
  animation: fade .6s ease-in-out;
}

.faq.active > .question > i{
  transform: rotate(180deg);
  transition: .3s;
}

@keyframes fade{
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.faq-child-contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.faq-child-contact-info > h3 {
  font-weight: 400;
  font-size: 1.5rem;
}

.faq-question {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}


.faq-question > h3 {
  color: #fff;
  font-weight: 300;
}

.faq-question > p {
  margin-bottom: 25px;
  margin-top: 5px;
  font-weight: 300;
  color: var(--e-global-color-text);
}


footer {
  background-color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0px;
}

.footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr .8fr;
  justify-content: space-between;
  border-top: 1px solid #343434;
  padding-top: 90px;
}

.single-short-link-footer-section {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.single-short-link-footer-section > h3 {
  color: #fff;
  margin-bottom: 0;
  font-size: 1.3rem;
}

.single-short-link-footer-section > ul {
  list-style-type: none;
  padding: 0;
  margin-top: 13px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.single-short-link-footer-section > ul > li {
  width: 100%;
}

.single-short-link-footer-section > ul > li > a {
  color: #ddd;
  letter-spacing: .5px;
  font-weight: 500;
  font-size: .9rem;
}

.footer-copy-right {
  padding: 50px 0;
  color: #909090;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 50px;
  letter-spacing: .5px;
}